import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { getMobileOS } from "@/utils/device";
import { KASKO, MOBILE_APP, OSAGO } from "@/utils/constants";

import { getNumberFromLocalStorage } from "../get-number-from-local-storage";

/**
 * Hook for getting data and storing the state about the SmartAppBanner component
 */
export const useSmartAppBanner = (pagesToShow = [KASKO, OSAGO, MOBILE_APP]) => {
  const END_TIME_OF_HIDING_SMART_APP_BANNER = "endTimeOfHidingSmartAppBanner";
  const router = useRouter();
  const [show, setShow] = useState(false);
  const isPageWithSmartAppBanner = router.query.slug && pagesToShow.includes(router.query.slug[0]);
  const mobileOS = getMobileOS();

  useEffect(() => {
    if (
      isPageWithSmartAppBanner &&
      ["android", "ios"].includes(mobileOS) &&
      getNumberFromLocalStorage(END_TIME_OF_HIDING_SMART_APP_BANNER) < Date.now()
    ) {
      setShow(true);
    }
  }, [isPageWithSmartAppBanner, mobileOS]);

  return { show, setShow, mobileOS, endTimeOfHidingSmartAppBanner: END_TIME_OF_HIDING_SMART_APP_BANNER };
};
