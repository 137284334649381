import { Image, ImageFormat } from "@/components/sections/types";

type StrapiImageFormats = "large" | "medium" | "small" | "thumbnail";

const formatsWeight: Record<StrapiImageFormats, number> = {
  large: 3,
  medium: 2,
  small: 1,
  thumbnail: 0,
};

const getFormatWeight = (format: string) => formatsWeight[format as StrapiImageFormats] || 0;

const findLargestFormat = (formats: Record<StrapiImageFormats, ImageFormat>) => {
  const sortedFormats = Object.keys(formats).sort(
    (a, b) => getFormatWeight(b) - getFormatWeight(a)
  ) as StrapiImageFormats[];

  if (!sortedFormats.length) {
    return null;
  }

  return formats[sortedFormats[0]];
};

const getUrl = (url: string) => {
  if (!url) {
    return "";
  }

  // Return the full URL if the media is hosted on an external provider
  if (url.startsWith("http") || url.startsWith("//")) {
    return url;
  }

  // Otherwise prepend the URL path with the Strapi URL
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"}${url}`;
};

/**
 * image либо урл картинки, либо объект Image из страпи
 */
export const getStrapiMedia = (image: string | Image | undefined, size?: StrapiImageFormats) => {
  if (!image) {
    return "";
  }

  if (typeof image === "string") {
    return getUrl(image);
  }

  // подбираем изображение подходящего размера, если такого размера не будет, то вернется самый большой из доступных либо несжатое изображение
  if (size && image?.formats) {
    const availableFormats = Object.keys(image.formats);

    if (availableFormats.includes(size)) {
      return getUrl(image.formats[size].url);
    }

    const largestFormat = findLargestFormat(image.formats);

    if (!availableFormats.includes(size) && largestFormat) {
      return getUrl(largestFormat.url);
    }
  }

  return getUrl(image.url || "");
};
