import React from "react";
import cn from "classnames";
import { MobileOS } from "@/utils/device";
import { getNumberFromLocalStorage } from "@/utils/get-number-from-local-storage";

import styles from "./index.module.scss";

interface SmartAppBannerProps {
  mobileOS: MobileOS;
  setShow(showSmartAppBanner: boolean): void;
  endTimeOfHidingSmartAppBanner: string;
}

export const SmartAppBanner = React.memo<SmartAppBannerProps>(function SmartAppBanner({
  mobileOS,
  setShow,
  endTimeOfHidingSmartAppBanner,
}) {
  const DAYS_TO_SHOW_AFTER_CLOSE_BANNER = 15;
  const DAYS_TO_SHOW_AFTER_VIEW_BANNER = 90;
  const appHref: Record<string, string> = {
    ios: "https://redirect.appmetrica.yandex.com/serve/315474353179400359",
    android: "https://redirect.appmetrica.yandex.com/serve/315474354018248399",
  };

  const setEndTimeOfHiding = (days: number): void => {
    const newEndTimeOfHiding = Date.now() + days * 86400000;

    if (newEndTimeOfHiding > getNumberFromLocalStorage(endTimeOfHidingSmartAppBanner)) {
      localStorage.setItem(endTimeOfHidingSmartAppBanner, String(newEndTimeOfHiding));
    }
  };

  const handleCloseButtonClick = () => {
    setEndTimeOfHiding(DAYS_TO_SHOW_AFTER_CLOSE_BANNER);
    setShow(false);
  };

  const handleViewButtonClick = () => {
    setEndTimeOfHiding(DAYS_TO_SHOW_AFTER_VIEW_BANNER);
  };

  return (
    <div id="display_mobile_smartbanner" className={cn(styles.smartbanner, styles[`smartbanner__${mobileOS}`])}>
      <div className={styles.smartbanner__container}>
        <span onClick={handleCloseButtonClick} className={styles.smartbanner__close}>
          ×
        </span>
        <svg
          className={styles.smartbanner__icon}
          width="1088"
          height="1088"
          viewBox="0 0 1088 1088"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="1088" height="1088" rx="50" fill="#FF0550" />
          <path
            d="M828.486 547.917C828.486 459.521 756.956 387.845 668.741 387.845C625.908 387.845 587.069 404.764 558.372 432.242C529.703 404.764 490.837 387.845 448.004 387.845C405.029 387.845 366.021 404.877 337.324 432.554C334.887 407.46 313.782 387.845 288.117 387.845C260.808 387.845 238.655 410.044 238.655 437.408C238.655 464.773 260.808 486.971 288.117 486.971C292.593 486.971 296.899 486.318 301.006 485.211C292.819 504.457 288.258 525.662 288.258 547.917L288.315 697.344H387.295L387.324 547.917C387.324 514.336 414.491 487.113 448.004 487.113C481.516 487.113 508.684 514.336 508.684 547.917L508.655 697.344H607.806L608.089 547.917C608.089 514.336 635.256 487.113 668.769 487.113C702.282 487.113 729.449 514.336 729.449 547.917L729.506 697.344H828.372L828.486 547.917Z"
            fill="white"
          />
        </svg>
        <div className={styles.smartbanner__info}>
          <div className={styles.smartbanner__title}>Mafin</div>
          <div>AbsolutTech LLC</div>
          <span>Бесплатно</span>
        </div>
        <a
          id="click_mobile_smartbanner"
          href={appHref[mobileOS]}
          target="_blank"
          rel="noreferrer"
          className={styles.smartbanner__button}
        >
          <span className={styles.smartbanner__button_text} onClick={handleViewButtonClick}>
            Загрузить
          </span>
        </a>
      </div>
    </div>
  );
});
