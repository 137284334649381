import { Link } from "@/components/sections/types";

interface ReturnType {
  href: string;
  rel: string;
  target?: string;
}

export const strapiLinkComponentToAnchorProps = (link: Link): ReturnType => {
  const relArray: string[] = [];
  let target;

  if (link?.relNofollow) {
    relArray.push("nofollow");
  }

  if (link?.newTab) {
    relArray.push("noopener", "noreferrer");
    target = "_blank";
  }

  return { href: link?.url, rel: relArray.join(" "), target };
};
