export const Colors = {
  corporate: "#FF0550",
  corporateMiddle: "#E60548",
  corporateDark: "#CC0440",
  corporateLight: "#FFE6ED",
  primary: "#1A76E1",
  primaryMiddle: "#1769C8",
  primaryDark: "#13539C",
  primaryDisabled: "#A9C6E7",
  main: "#2F3542",
  mainDark: "#282D37",
  additional: "#90A1AC",
  additionalLight: "#C8D0D6",
  bg: "#EEF1F6",
  bgLight: "#F4F6F9",
  disabledDark: "#C5C7C9",
  disabledLight: "#E5E5E5",
  white: "#FFFFFF",
  warning: "#FFAA05",
  warningBg: "#FFF7E6",
  error: "#FF4B2B",
  errorBg: "#FFEFE5",
  success: "#3FD295",
  stars: "#FFC805",
};
