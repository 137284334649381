import { FC, HTMLProps, ReactNode, useMemo } from "react";
import cn from "classnames";
import { text, titles } from "@/components/shared/typography/constants";
import { Colors } from "@/utils/constants/colors";

import styles from "./index.module.scss";

const types = [...text, ...titles] as const;

const textStyles = {
  simpleText: styles.simpleText,
  lidTitle: styles.lidTitle,
  buttonText: styles.buttonText,
  bigLink: styles.bigLink,
  ultraLittleText: styles.ultraLittleText,
  inputText: styles.inputText,
  errorText: styles.errorText,
  littleButtonText: styles.littleButtonText,
  littleLink: styles.littleLink,
  littleText: styles.littleText,
  inputTitle: styles.inputTitle,
  numberText: styles.numberText,
  semiboldSimpleText: styles.semiboldSimpleText,
  h1: styles.h1,
  h2: styles.h2,
  h3: styles.h3,
  h4: styles.h4,
  h5: styles.h5,
};

interface TypographyProps extends HTMLProps<HTMLDivElement> {
  type: typeof types[number];
  useParagraph?: boolean;
  children: string | ReactNode;
  color?: keyof typeof Colors;
  className?: string;
}

export const Typography: FC<TypographyProps> = ({ type, color, className, useParagraph, children, ...props }) => {
  const style = useMemo(() => {
    if (!color) {
      return {};
    }

    return { style: { color: Colors[color] || color } };
  }, [color]);

  if (!useParagraph && titles.includes(type as typeof titles[number])) {
    if (type === "h1") {
      return (
        <h1 {...style} {...props} className={cn(styles.title, styles.h1, className)}>
          {children}
        </h1>
      );
    } else if (type === "h2") {
      return (
        <h2 {...style} {...props} className={cn(styles.title, styles.h2, className)}>
          {children}
        </h2>
      );
    } else if (type === "h3") {
      return (
        <h3 {...style} {...props} className={cn(styles.title, styles.h3, className)}>
          {children}
        </h3>
      );
    } else if (type === "h4") {
      return (
        <h4 {...style} {...props} className={cn(styles.title, styles.h4, className)}>
          {children}
        </h4>
      );
    } else if (type === "h5") {
      return (
        <h5 {...style} {...props} className={cn(styles.title, styles.h5, className)}>
          {children}
        </h5>
      );
    }
  }

  return (
    <p {...style} {...props} className={cn(textStyles[type as typeof text[number]], className)}>
      {children}
    </p>
  );
};
