export const deviceType = (): string => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  const find = (needle: string) => userAgent.indexOf(needle) !== -1;

  const isIphone = () => !isWindows() && find("iphone");

  const isIpad = () => find("ipad");

  const isAndroid = () => !isWindows() && find("android");

  const isAndroidPhone = () => isAndroid() && find("mobile");

  const isAndroidTablet = () => isAndroid() && !find("mobile");

  const isBlackberry = () => find("blackberry") || find("bb10") || find("rim");

  const isBlackberryPhone = () => isBlackberry() && !find("tablet");

  const isBlackberryTablet = () => isBlackberry() && find("tablet");

  const isWindows = () => find("windows");

  const isWindowsPhone = () => isWindows() && find("phone");

  const isWindowsTablet = () => isWindows() && find("touch") && !isWindowsPhone();

  const isFxos = () => (find("(mobile;") || find("(tablet;")) && find("; rv:");

  const isFxosPhone = () => isFxos() && find("mobile");

  const isFxosTablet = () => isFxos() && find("tablet");

  const isMeego = () => find("meego");

  const isMobile = () =>
    isAndroidPhone() || isIphone() || isWindowsPhone() || isBlackberryPhone() || isFxosPhone() || isMeego();

  const isTablet = () => isIpad() || isAndroidTablet() || isBlackberryTablet() || isWindowsTablet() || isFxosTablet();

  const isDesktop = () => !isMobile() && !isTablet();

  let deviceType = "";

  if (isMobile()) {
    deviceType = "mobile";
  }

  if (isTablet()) {
    deviceType = "tablet";
  }

  if (isDesktop()) {
    deviceType = "desktop";
  }

  return deviceType;
};

export const getURL = () => window.location.href;

export const getUTMData = () => {
  const { utm_source = "", utm_medium = "", utm_campaign = "", utm_term = "" } = getUtmMarks();

  return {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
  };
};

export const getRefferer = (): string => document.referrer || "direct link";

export const getLanguage = (): string => {
  const { language } = navigator;

  return language;
};

export const getBrowser = (): string => {
  const ua = navigator.userAgent;

  if (ua.search(/Chrome/) > 0) {
    return "Google Chrome";
  }

  if (ua.search(/Firefox/) > 0) {
    return "Firefox";
  }

  if (ua.search(/Opera/) > 0) {
    return "Opera";
  }

  if (ua.search(/Safari/) > 0) {
    return "Safari";
  }

  if (ua.search(/MSIE/) > 0) {
    return "Internet Explorer";
  }

  return "Не определен";
};

export const getOS = (): string => {
  const userDeviceArray = [
    { device: "Android", platform: /Android/ },
    { device: "iPhone", platform: /iPhone/ },
    { device: "iPad", platform: /iPad/ },
    { device: "Symbian", platform: /Symbian/ },
    { device: "Windows Phone", platform: /Windows Phone/ },
    { device: "Tablet OS", platform: /Tablet OS/ },
    { device: "Linux", platform: /Linux/ },
    { device: "Windows", platform: /Windows NT/ },
    { device: "Macintosh", platform: /Macintosh/ },
  ];

  const platform = navigator.userAgent;

  const getPlatform = (): string => {
    for (const i in userDeviceArray) {
      if (userDeviceArray[i].platform.test(platform)) {
        return userDeviceArray[i].device;
      }
    }

    return `Неизвестная платформа!${platform}`;
  };

  return getPlatform();
};

export const getCookies = () => {
  const cookiesArray = document.cookie.split(";");
  const clearCookiesArray = cookiesArray.map((cookie) => {
    const index = cookie.indexOf(" ");

    return cookie.slice(index + 1);
  });

  const getOneCookie = (name: string) => clearCookiesArray.find((element) => element.includes(name));

  const getCookieValue = (cookie: string | undefined, name: string) => (cookie ? cookie.slice(name.length) : "");

  const _ga = getCookieValue(getOneCookie("_ga="), "_ga=");
  const _gid = getCookieValue(getOneCookie("_gid="), "_gid=");
  const _ym_uid = getCookieValue(getOneCookie("_ym_uid="), "_ym_uid=");

  return {
    _ga,
    _gid,
    _ym_uid,
  };
};

export const getScreenResolution = (): string => {
  const { width } = window.screen;
  const { height } = window.screen;

  return `${width}x${height}`;
};

export const getQueryParams = () =>
  window.location.search
    .slice(1)
    .split("&")
    .reduce((res: Record<string, string>, queryParam) => {
      const [name, value] = queryParam.split("=");

      if (name) {
        res[name] = decodeURI(value);
      }

      return res;
    }, {});

export const getUtmMarks = () => {
  const queryParams = getQueryParams();

  return Object.keys(queryParams).reduce((res: Record<string, string>, queryParamName) => {
    if (queryParamName.startsWith("utm_")) {
      res[queryParamName] = queryParams[queryParamName];
    }

    return res;
  }, {});
};

export type MobileOS = "ios" | "android" | "other";

export const getMobileOS = (): MobileOS => {
  if (typeof window !== "undefined") {
    if (/android/i.test(navigator.userAgent)) {
      return "android";
    } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      return "ios";
    }
  }

  return "other";
};
