import { memo, ReactNode } from "react";
import { IGlobalContext } from "@/utils/globalContext/types";
import { getStrapiMedia } from "@/utils/media";
import Head from "next/head";
import { Page } from "@/utils/api/types/main-site";

import { GlobalContext } from "../../pages/_app";

import { Layout } from ".";

interface PageContainerProps {
  global: IGlobalContext | null;
  children: ReactNode;
  jsonLd?: Page["jsonLd"];
}

export const PageContainer = memo<PageContainerProps>(({ children, global, jsonLd }) => {
  if (!global) {
    return <div>{children}</div>;
  }

  return (
    <GlobalContext.Provider value={global}>
      <Head>
        <>
          <link rel="shortcut icon" href={getStrapiMedia(global.favicon.url)} />
          {jsonLd && (
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd, null, 2) }} />
          )}
        </>
      </Head>
      <Layout>{children}</Layout>
    </GlobalContext.Provider>
  );
});
