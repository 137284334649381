export const text = [
  "simpleText",
  "lidTitle",
  "buttonText",
  "bigLink",
  "ultraLittleText",
  "inputText",
  "errorText",
  "littleButtonText",
  "littleLink",
  "littleText",
  "inputTitle",
  "numberText",
  "semiboldSimpleText",
] as const;

export const colors = [
  "corporate",
  "corporateMiddle",
  "corporateDark",
  "corporateLight",
  "primary",
  "primaryMiddle",
  "primaryDark",
  "primaryDisabled",
  "main",
  "mainDark",
  "additional",
  "additionalLight",
  "bg",
  "bgLight",
  "disabledDark",
  "disabledLight",
  "white",
  "warning",
  "warningBg",
  "error",
  "errorBg",
  "success",
  "stars",
];

export const titles = ["h1", "h2", "h3", "h4", "h5"] as const;
