export const EMAIL_REG_EXP =
  /^((([0-9A-Za-z]{1}[-0-9A-z.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я.]{1,}[0-9А-Яа-я]{1}))@([А-ЯЁа-яёA-Za-z0-9!#$%&'*+—/=?^_`{|}~.]{1,})\.([А-ЯЁа-яёA-Za-z0-9!#$%&'*+—/=?^_`{|}~.]{1,}))$/u;

export const PASSPORT_REG_EXP = /^[0-9]{2} [0-9]{2} [0-9]{6}$/;

export const DRIVER_LICENSE_REG_EXP = /^\d{2} ([0-9]{2}|[АВЕКМНОРСТУХ]{2}) \d{6}$/i; // кириллические символы

export const PHONE_REG_EXP = /^\+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2}$/;

export const DATE_REG_EXP = /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)[0-9]{2}$/;
