import { HeaderLink, HeaderLinkEnhancerParams } from "@mafin/ui-kit";
import {
  Footer,
  Header,
  LinkForHeaderSection,
  LinkWithChildrenSection,
  RelativeLinkForHeader,
} from "@/utils/globalContext/types";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";

interface HeaderNavigation extends HeaderLink {
  children?: HeaderLink[];
}

const getMenuItem = ({ url, text, newTab, iconNew }: LinkForHeaderSection): HeaderLink => {
  return { href: url, text, newTab, iconNew };
};

const getMenuItemWithChildren = ({
  child_link,
  relative_link,
}: LinkWithChildrenSection): HeaderLink & RelativeLinkForHeader & { children: HeaderLink[] } => {
  const linkAs = ({ href, className, children }: HeaderLinkEnhancerParams) => (
    <a href={href} className={className}>
      {children}
    </a>
  );

  const children: HeaderLink[] = child_link.map(({ url, id, relNofollow, icon, ...rest }) => ({
    href: url,
    icon: icon || undefined,
    ...rest,
  }));

  return {
    href: relative_link?.url || "",
    text: relative_link?.text || "",
    newTab: relative_link?.newTab,
    gridColumn: relative_link?.gridColumn ?? 2,
    iconNew: relative_link?.iconNew,
    icon: relative_link?.icon || undefined,
    linkAs,
    children,
  };
};

export const getHeaderProps = (header: Header, showSmartAppBanner: boolean) => {
  const navigation: HeaderNavigation[] = [];
  let phone = "";

  header?.forEach((element) => {
    if (element.__component === "links.link-for-header") {
      navigation.push(getMenuItem(element));
    } else if (element.__component === "links.link-with-children") {
      navigation.push(getMenuItemWithChildren(element));
    } else if (element.__component === "partials.header-phone") {
      if (!element.hidden) {
        ({ phone } = element);
      }
    }
  });

  return { navigation, phone, ...(showSmartAppBanner && { mobileOffset: 128 }) };
};

export const getFooterProps = (footer: Footer) => {
  const links = footer?.links?.map((link) => {
    if (link.newTab || link.relNofollow) {
      const linkAs = ({ href, className, children }: HeaderLinkEnhancerParams) => (
        <a {...strapiLinkComponentToAnchorProps(link)} href={href} className={className}>
          {children}
        </a>
      );

      return { title: link?.text, href: link?.url, linkAs };
    }

    return { title: link?.text, href: link?.url };
  });

  const socials = footer?.socials?.map((social) => ({ type: social?.text, href: social?.url }));

  const contacts = footer?.contacts?.map((contact) => ({ type: contact?.text, href: contact?.url }));

  const liglLink = { title: footer?.ligLink?.text, href: footer?.ligLink?.url };

  if (footer) {
    const { skolkovo, agreement, mafinMedia, phone, ligl } = footer;

    return { links, socials, contacts, liglLink, skolkovo, agreement, mafinMedia, phone, ligl };
  }
};
