import React, { useContext, memo, useMemo, MouseEvent, ReactNode, useState } from "react";
import { useRouter } from "next/router";
import cn from "classnames";
import { Header, Footer, HeaderProps } from "@mafin/ui-kit";
import { getFooterProps, getHeaderProps } from "@/components/layout/utils";
import { useSmartAppBanner } from "@/utils/hooks/use-smart-app-banner";
import {
  KASKO,
  MOBILE_APP,
  OSAGO,
  ACCOUNT_URL,
  ACCOUNT_POLICY_URL,
  MAIN_URL,
  ACCOUNT_CALC_URL,
  ACCOUNT_SUBSCRIPTIONS_URL,
} from "@/utils/constants";
import { useProfile } from "@/utils/profile/profile-provider";

import { GlobalContext } from "../../pages/_app";
import { SmartAppBanner } from "../elements/smart-app-banner";

import styles from "./Layout.module.scss";

export const Layout = memo<{ children: ReactNode }>(function Layout({ children }) {
  const { header, footer } = useContext(GlobalContext);
  const smartAppBanner = useSmartAppBanner([KASKO, OSAGO, MOBILE_APP]);
  const router = useRouter();
  const { policies, calculations, subscriptions } = useProfile();
  const [menuExpanded, setMenuExpanded] = useState(false);

  const authMenu: HeaderProps["authMenu"] = [
    {
      title: "Личный кабинет",
      href: ACCOUNT_URL,
      onClick: (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        router.push(ACCOUNT_URL);
      },
    },
    {
      title: "Полисы",
      counter: policies.length,
      href: ACCOUNT_POLICY_URL,
      onClick: (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        router.push(ACCOUNT_POLICY_URL);
      },
    },
    {
      title: "Расчёты",
      counter: Object.keys(calculations).length,
      href: ACCOUNT_CALC_URL,
      onClick: (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        router.push(ACCOUNT_CALC_URL);
      },
    },
    {
      title: "Подписки",
      counter: Object.keys(subscriptions).length,
      href: ACCOUNT_SUBSCRIPTIONS_URL,
      onClick: (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        router.push(ACCOUNT_SUBSCRIPTIONS_URL);
      },
    },
  ];

  const logout: HeaderProps["logout"] = {
    href: MAIN_URL,
    onClick: (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      router.push(MAIN_URL);
    },
  };

  const headerProps = useMemo(() => getHeaderProps(header, smartAppBanner.show), [header, smartAppBanner.show]);

  const footerProps = useMemo(() => getFooterProps(footer), [footer]);

  return (
    <div className={styles.layout}>
      <div className={styles.container} id="layout__container">
        <div className={styles.headerWrapper}>
          {smartAppBanner.show && <SmartAppBanner {...smartAppBanner} />}
          {header?.length && (
            <Header
              {...headerProps}
              authMenu={authMenu}
              logout={logout}
              setHeaderIsExpanded={setMenuExpanded}
              currentPath={router.asPath}
            />
          )}
        </div>
        <div className={cn(styles.content, smartAppBanner.show && styles.contentWithSmartAppBanner)}>
          <div className={cn(styles.backDrop, menuExpanded && styles.menuExpanded)} />
          {children}
        </div>
      </div>
      {footer && <Footer {...footerProps!} />}
    </div>
  );
});
